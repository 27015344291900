@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_bold.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_medium.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_bold_condensed.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_bold_condensed.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  font-stretch: condensed;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_bold_narrow.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_bold_narrow.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  font-stretch: semi-condensed;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_bold_expanded.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_bold_expanded.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  font-stretch: expanded;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_medium_condensed.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium_condensed.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  font-stretch: condensed;
}

@font-face {
  font-family: 'ALS Wagon Medium Condensed';
  src: url('../../../public/assets/fonts/als_wagon_medium_condensed.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium_condensed.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_medium_narrow.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium_narrow.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  font-stretch: semi-condensed;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_medium_expanded.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_medium_expanded.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  font-stretch: expanded;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_regular_condensed.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_regular_condensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-stretch: condensed;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_regular_narrow.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_regular_narrow.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-stretch: semi-condensed;
}

@font-face {
  font-family: 'ALS Wagon';
  src: url('../../../public/assets/fonts/als_wagon_regular_expanded.woff2') format('woff2'),
    url('../../../public/assets/fonts/als_wagon_regular_expanded.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-stretch: expanded;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-LightItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss Book';
  src: url('../../../public/assets/fonts/ALSHauss-Book.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-Black.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-Bold.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss Hairline';
  src: url('../../../public/assets/fonts/ALSHauss-HairlineItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-HairlineItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-BoldItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-Regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-Medium.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss Book';
  src: url('../../../public/assets/fonts/ALSHauss-BookItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-BlackItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-Light.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-MediumItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss Hairline';
  src: url('../../../public/assets/fonts/ALSHauss-Hairline.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Hairline.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-Thin.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-ThinItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('../../../public/assets/fonts/ALSHauss-RegularItalic.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHauss-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss VF';
  src: url('../../../public/assets/fonts/ALSHaussVF.woff2') format('woff2'),
    url('../../../public/assets/fonts/ALSHaussVF.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* MTS Extended */

@font-face {
  font-family: 'MTS';
  src: url('../../../public/assets/fonts/MTSExtended-Black.woff2') format('woff2'),
    url('../../../public/assets/fonts/MTSExtended-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS';
  src: url('../../../public/assets/fonts/MTSExtended-Bold.woff2') format('woff2'),
    url('../../../public/assets/fonts/MTSExtended-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS';
  src: url('../../../public/assets/fonts/MTSExtended-Medium.woff2') format('woff2'),
    url('../../../public/assets/fonts/MTSExtended-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS';
  src: url('../../../public/assets/fonts/MTSExtended-Regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/MTSExtended-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* MTS Ultra Extended */

@font-face {
  font-family: 'MTS Ultra';
  src: url('../../../public/assets/fonts/MTSUltraExtended-Black.woff2') format('woff2'),
    url('../../../public/assets/fonts/MTSUltraExtended-Black.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Ultra';
  src: url('../../../public/assets/fonts/MTSUltraExtended-Bold.woff2') format('woff2'),
    url('../../../public/assets/fonts/MTSUltraExtended-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Ultra';
  src: url('../../../public/assets/fonts/MTSUltraExtended-Light.woff2') format('woff2'),
    url('../../../public/assets/fonts/MTSUltraExtended-Light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Ultra';
  src: url('../../../public/assets/fonts/MTSUltraExtended-Regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/MTSUltraExtended-Regular.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* MTS Wide */

@font-face {
  font-family: 'MTS Wide';
  src: url('../../../public/assets/fonts/MTSWide-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Wide';
  src: url('../../../public/assets/fonts/MTSWide-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Wide';
  src: url('../../../public/assets/fonts/MTSWide-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Wide';
  src: url('../../../public/assets/fonts/MTSWide-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Wide';
  src: url('../../../public/assets/fonts/MTSWide-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* MTS Text */

@font-face {
  font-family: 'MTS Text';
  src: url('../../../public/assets/fonts/MTSText-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Text';
  src: url('../../../public/assets/fonts/MTSText-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Text';
  src: url('../../../public/assets/fonts/MTSText-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Text';
  src: url('../../../public/assets/fonts/MTSText-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* MTS Compact */

@font-face {
  font-family: 'MTS Compact';
  src: url('../../../public/assets/fonts/MTSCompact-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Compact';
  src: url('../../../public/assets/fonts/MTSCompact-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Compact';
  src: url('../../../public/assets/fonts/MTSCompact-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MTS Compact';
  src: url('../../../public/assets/fonts/MTSCompact-Regular.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* TELE2 Rooftop */

@font-face {
  font-family: 'TELE2 Rooftop Medium';
  src: url('../../../public/assets/fonts/t2-Rooftop-Medium.woff2') format('woff2'),
    url('../../../public/assets/fonts/t2-Rooftop-Medium.woff') format('woff'),
    url('../../../public/assets/fonts/t2-Rooftop-Medium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TELE2 Rooftop Regular';
  src: url('../../../public/assets/fonts/t2-Rooftop-Regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/t2-Rooftop-Regular.woff') format('woff'),
    url('../../../public/assets/fonts/t2-Rooftop-Regular.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

/* TELE2 HalvarBreit */

@font-face {
  font-family: 'TELE2 HalvarBreit ExtraBold';
  src: url('../../../public/assets/fonts/T2HalvarBreit-ExtraBold.woff2') format('woff2'),
    url('../../../public/assets/fonts/T2HalvarBreit-ExtraBold.woff') format('woff'),
    url('../../../public/assets/fonts/T2HalvarBreit-ExtraBold.otf') format('opentype'),
    url('../../../public/assets/fonts/T2HalvarBreit-ExtraBold.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

/* TELE2 HalvarStencilBreit */

@font-face {
  font-family: 'TELE2 HalvarStencilBreit ExtraBold';
  src: url('../../../public/assets/fonts/T2HalvarStencilBreit-ExtraBoldMidGap.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}
